// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-[pid]-jsx": () => import("./../../../src/pages/[pid].jsx" /* webpackChunkName: "component---src-pages-[pid]-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-admin-add-post-jsx": () => import("./../../../src/pages/admin/add-post.jsx" /* webpackChunkName: "component---src-pages-admin-add-post-jsx" */),
  "component---src-pages-admin-admin-layout-admin-layout-jsx": () => import("./../../../src/pages/admin/AdminLayout/AdminLayout.jsx" /* webpackChunkName: "component---src-pages-admin-admin-layout-admin-layout-jsx" */),
  "component---src-pages-admin-dashboard-jsx": () => import("./../../../src/pages/admin/dashboard.jsx" /* webpackChunkName: "component---src-pages-admin-dashboard-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-posts-index-jsx": () => import("./../../../src/pages/posts/index.jsx" /* webpackChunkName: "component---src-pages-posts-index-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */)
}

